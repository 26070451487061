<template>
  <q-dialog v-model="isShowDialog" persistent>
    <q-card
      class="column"
      style="width: 55vw; min-width: 400px; max-width: 90vw; min-height: 438px"
    >
      <q-card-section class="container">
        <div class="header">
          <h1>Como você está se sentindo hoje?</h1>
          <q-btn
            color="default-pink"
            icon="close"
            round
            @click="$emit('closeDialog')"
          />
        </div>
        <div class="container-moods">
          <div
            class="mood"
            v-for="(emoji, index) in emojis"
            :key="index"
            @click="
              (event) => {
                setMood(event, emoji.id);
              }
            "
            :class="{ clicked: currentEmoji[index] }"
          >
            <q-img
              :src="urlAwsBucket + emoji.emoji_path"
              spinner-color="primary"
              class="cursor-pointer"
              spinner-size="82px"
              style="width: 130px; height: 130px; border-radius: 50%"
            />
            <h2>{{ emoji.title }}</h2>
          </div>
        </div>
      </q-card-section>
      <a
        href="https://www.flaticon.com/br/stickers-gratis/emoji"
        target="_blank"
        rel="noopener noreferrer"
        class="copyright"
        >Emoji figurinhas criadas por Stickers - Flaticon</a
      >
    </q-card>
  </q-dialog>
</template>

<script>
//----UTILS
import { ENV } from "@/utils/env";

/* Services */

import UserServices from "@/services/UserServices";

import { ref, onMounted, watch } from "vue";

import { useRouter } from "vue-router";

export default {
  name: "DialogSetMood",
  props: {
    confirm: {
      required: true,
      type: Boolean,
    },
  },
  emits: ["closeDialog"],
  setup(props) {
    const urlAwsBucket = ENV.URL_AWS_BUCKET;
    const userServices = new UserServices();
    const emojis = ref([]);
    const currentEmoji = ref([]);
    const router = new useRouter();
    const isShowDialog = ref(props.confirm);

    async function setMood(event, id) {
      const moods = document.querySelectorAll(".mood");

      moods.forEach((mood) => {
        mood.classList.remove("clicked");
      });
      event.currentTarget.classList.add("clicked");

      await userServices
        .updateEmojiProfile(id)
        .then((response) => {
          listMood();
          setTimeout(() => {
            router.go();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function listMood() {
      await userServices
        .getAllEmoji()
        .then((response) => {
          emojis.value = response.data;
          currentMood();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function currentMood() {
      if (props.confirm) {
        await userServices.getUserEmoji().then((response) => {
          emojis.value.map((emoji, index) => {
            if (emoji.emoji_path == response.data[0]?.emoji_path) {
              currentEmoji.value.push(true);
            } else {
              currentEmoji.value.push(false);
            }
          });
        });
      }
    }

    /* Lificycles */

    onMounted(() => {
      listMood();
    });

    watch(
      () => props.confirm,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    return {
      setMood,
      urlAwsBucket,
      emojis,
      currentEmoji,
      isShowDialog,
    };
  },
};
</script>

<style scoped>
h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: -0.5px;
  color: #000000;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-moods {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 130px));
  margin-top: 1.875rem;
  justify-content: center;
  gap: 40px;
}

.header {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
}

.mood {
  text-align: center;
  transition: all 0.5s;
  opacity: 0.5;
}

.mood:hover {
  opacity: 1;
}

.clicked {
  opacity: 1;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.25px;
  margin-top: 0.5rem;
  color: #404040;
}

.copyright {
  font-size: 11.2px;
  text-decoration: none;
  text-align: center;
  font-family: "Montserrat";
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #404040;
  margin-top: 1.875rem;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>